import React from 'react';
import PolicyContent from 'components/Policy/PolicyContent';
import PolicyHeader from 'components/Policy/PolicyHeader';
import LightTheme from 'layouts/Light';

const PrivacyPolicy = () => {
  return (
    <LightTheme>
      <PolicyHeader />
      <PolicyContent />
    </LightTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>UniService.Vn - Privacy Policy</title>
    </>
  );
};

export default PrivacyPolicy;
